.preview_title, .truncated-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.input-container-inner {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    padding-left: 5px;
    align-items: center;
    margin: auto;
    border-radius: 18px
}

.count-wrapper {
    width: 100%;
    margin-bottom: 5px
}

.chat-logo-voice:hover, .sendtool:hover {
    filter: brightness(105%);
    -webkit-filter: brightness(105%)
}

.count-container {
    color: #000;
    padding-left: 15px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    padding-bottom: 10px;
    text-align: left
}

.sendtool {
    position: relative
}

.sendtool .sendtooltext {
    text-wrap: nowrap;
    width: auto;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 7px;
    position: absolute;
    z-index: 1;
    bottom: 63px;
    left: -25px
}

.sendtool .sendtooltext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent
}

.select_img_wrapper {
    position: absolute;
    top: -110px;
    left: 0;
    width: 100%;
    border-radius: 10px;
    background: #ffff;
    max-height: 130px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .06), 0 1px 1px -2px rgba(0, 0, 0, .06), 0 1px 5px 0 rgba(0, 0, 0, .06)
}

.preview_detai_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.select_img_inner {
    padding: 10px;
    width: 100%
}

.select_img_inner p {
    color: #000;
    margin: 5px 0
}

.preview_details {
    display: flex;
    gap: 20px;
    align-items: center
}

.image_preview_close_img {
    width: 20px;
    cursor: pointer;
    opacity: .5;
    margin-right: 20px
}

.select_img_wrapper:hover .image_preview_close_img {
    opacity: 1
}

.preview_title {
    color: #293041;
    font-weight: 500
}

.listening-wrapper {
    color: #ec0808;
    position: absolute;
    top: 22px;
    z-index: 1000000;
    right: 80px;
    font-weight: 500
}

@media (max-width:767px) {
    .image_preview_close_img {
        margin-right: 10px
    }

    .image_preview_close {
        display: flex;
        align-items: center
    }

    .count-container {
        padding-left: 25px;
        padding-bottom: 2px
    }

    .input-container-inner {
        padding-left: 0
    }
}