.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000
}

.share-modal {
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    text-align: center;
    width: 96%;
    max-width: 620px
}

.media-container, .media-wrapper {
    width: 100%;
    gap: 10px;
    display: flex
}

.share-modal input {
    width: calc(100%);
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    font-size: 16px;
    display: inline-block;
    font-weight: 400;
    font-family: roboto;
    background-color: #eeeeee;
}

.share-modal input:focus-visible {
    outline: #bdbdbd solid 1px
}

.close-button, .copy-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 4px 4px 1px;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 5px
}

.media-container {
    justify-content: space-around
}

.close-button {
    background-color: transparent
}

.close-button img {
    width: 32px;
    opacity: 1;
    padding: 5px;
    border-radius: 10px
}

.close-button:hover {
    opacity: 1;
    background: #0000001a
}

.copy-button img {
    width: 16px;
    height: 16px;
    margin-right: 5px
}

.share-title {
    color: #212121
}

.social-media-title {
    color: #424242;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 11px
}

.copy-text, .share-close {
    color: #000;
    font-family: Roboto, sans-serif
}

.media-wrapper {
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 24px;
    overflow-x: auto
}

.media-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    height: 100px;
    width: 85px
}

.media-inner:hover {
    border-radius: 15px;
    background: #00000010
}

.media-inner-img {
    padding: 15px 8px 6px;
    width: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px
}

.media-inner-img img {
    height: 40px
}

.media-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 6px
}

.media-wrapper::-webkit-scrollbar-track {
    background: #cfd4dd;
    border-radius: 20px
}

.media-wrapper::-webkit-scrollbar-thumb {
    background-color: #dee7f5;
    border-radius: 20px;
    border: 1px solid #dee7f5;
    width: 8px
}

.copy-input {
    display: flex;
    padding-top: 20px;
    padding-bottom: 10px
}

.share-close {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px
}

.copy-link {
    align-items: center;
    border-radius: 10px;
    background: #fff;
    margin-left: 10px;
    border: 1px solid #e0e0e0;
    display: flex;
    padding-left: 4px;
    gap: 5px;
    cursor: pointer;
    min-width: 130px;
    justify-content: center
}

.copy-link:hover {
    background: #f5f5f5
}

.copytool {
    position: relative
}

.copytool .copytooltext {
    width: auto;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 7px;
    position: absolute;
    z-index: 1;
    bottom: 104%;
    left: -5% !important
}

.copytool .copytooltext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent
}

.copy-text {
    font-weight: 500;
    font-size: 14px
}

.copy-img {
    display: flex
}

@media (max-width:767px) {
    .share-title {
        text-align: left
    }

    .media-wrapper {
        flex-direction: column
    }

    .media-container {
        justify-content: space-around;
        gap: 0
    }

    .close-button img {
        position: relative;
        top: -6px;
        right: -6px
    }
}