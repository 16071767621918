.chat-type-container {
    width: 100%;
    display: flex;
    margin: auto;
}

.chat-type-inner {
    width: 100%;
}

.chat-type-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.chat-type-item-wrapper {
    width: 100%;
}

.chat-type-item {
    padding: 13.5px 15px 13.5px 20px;
    display: flex;
    background: #FFFFFFCC;
    gap: 20px;
    border-radius: 20px;
    align-items: center;
    cursor: pointer;
}

.chat-type-item:hover {
    background: #FFFFFF;
}

.chat-type-item img {
    width: 30px;
}

.chat-type-item-img {
    padding: 9px 9px 4px;
    background: #FFC60133;
    border-radius: 50px;
}

.chat-type-item p {
    margin: 0px;
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

}


.option-wrapper{
    width: 100%;
}

.option-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.options {}

.options1 , .options2 {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: space-between;
    padding-top: 20px;
}

.options2 {}

.option-item {}

.options-item img {
    max-width: 46px;
    min-width: 45px;
    width: 100%;
}

.options-item:hover .options-item img{
    filter: brightness(110%);
        transform: scale(1.03);
}

.options-item .option-text {}

.options-item  {
    height: 135px;
    align-items: center;
    -webkit-backdrop-filter: blur(2.5px);
    backdrop-filter: blur(2.5px);
    background: #ffffffe6;
    border: 0 solid #0000;
    border-radius: 26px;
    box-shadow: inset 0 -6px 5px -1px #0000001f, inset 0 0 1px -4px #0000001f;
    cursor: pointer;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    max-width: 135px;
    min-height: 125px;
    min-width: 80px;
    padding: 10px;
    width: 100%;

}

.options-item:hover{
    transform: scale(1.1);
    background: #fff !important;
}

.option-text p {
    color: #1a3054;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    margin: 0;
}

@media screen and (min-width: 767px) {

   .option-text p {
        font-size: 15px;
        line-height: 18px;
    }
}


@media (max-width:992px) {
    .chat-type-item p {
        font-size: 15px;
        line-height: 20px;
    }

    .chat-type-item {
        padding: 12px 15px 12px 15px;
        gap: 12px;
    }

    .chat-type-wrapper {
        margin: auto;
    }
}