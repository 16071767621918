.container-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
}

.est-background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
}

.container {
    position: relative;
    width: 100%;
    align-items: center;
    height: 100%;
    overflow-y: auto;
}

.estimationform {
    background-color: #ffffffdb;
    backdrop-filter: blur(10px);
    max-width: 900px;
    margin: 40px 20px 40px auto;
    border-radius: 8px;
    width: 50%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
}

.questionContainer {
    margin-Bottom: 20px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.questionContainer input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

input::placeholder {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}



.submitButton {
    width: 100%;
    padding: 10px;
    font-Size: 18px;
    background-Color: #ffc801;
    color: #fff;
    border: none;
    border-Radius: 4px;
    cursor: pointer;
}

.textarea-wrapper {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 100px;
    resize: vertical;
}

.questionContainer p{
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #616161;
    margin: 0px 0px 4px;
}

.textarea-wrapper::placeholder {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

.items-wrapper {
    display: flex;
}

.text-area-wrapper {
    flex-direction: column;
}

.input-wrapper {
    gap: 20px;
}

.input-inner {
    width: 50%;
}

/* Popup styling */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 8px;
    z-index: 9999;
}



/* Loader.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Dark semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
}

.popupContent,
.successMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    /* White background for better contrast */
    color: #000;
    /* Black text for better readability */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.circle-loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #ffc801;
    /* Blue color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

.popupText {
    font-size: 16px;
}

.successMessage {
    background: #4CAF50;
    /* Green background for success */
    color: #fff;
    /* White text */
    animation: fadeIn 0.5s ease-out;
}

.successText {
    font-size: 16px;
    margin-bottom: 10px;
}

.okButton {
    background-color: #fff;
    color: #4CAF50;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.okButton:hover {
    background-color: #ddd;
}

/* Animations */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width:992px) {
    .desktop_background {
        display: none;
    }

    .estimationform {
        width: 100%;
        margin: 40px auto 40px auto;
    }

    .container {
        padding: 20px;
    }

    .items-wrapper {
        flex-direction: column;
        gap: 0px;
        margin-bottom: 20px;
    }

    .input-inner {
        width: 100%;
    }
}

@media (min-width:992px) {
    .mobile_background {
        display: none;
    }
}