
/* Popup styling */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 8px;
    z-index: 9999;
}



/* Loader.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    /* Dark semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
}

.popupContent,
.successMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    /* White background for better contrast */
    color: #000;
    /* Black text for better readability */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.circle-loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #ffc801;
    /* Blue color */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

.popupText {
    font-size: 16px;
}

.successMessage {
    background: #4CAF50;
    /* Green background for success */
    color: #fff;
    /* White text */
    animation: fadeIn 0.5s ease-out;
}

.successText {
    font-size: 16px;
    margin-bottom: 10px;
}

.okButton {
    background-color: #fff;
    color: #4CAF50;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.okButton:hover {
    background-color: #ddd;
}

.wizard-section-container{
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
}

.printButtons{
    display: flex;
    justify-content: space-between;
}

/* Animations */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width:992px) {
    .desktop_background {
        display: none;
    }

    .estimationform {
        width: 100%;
        margin: 40px auto 40px auto;
    }

    .container {
        padding: 20px;
    }

    .items-wrapper {
        flex-direction: column;
        gap: 0px;
        margin-bottom: 20px;
    }

    .input-inner {
        width: 100%;
    }
}

@media (min-width:992px) {
    .mobile_background {
        display: none;
    }
}
















.error-message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f44336; /* Red background for error messages */
    color: #fff; /* White text color */
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  .rating-buttons{
    display: flex;
    width: 100%;
    gap: 20px;
  
  }
  
  .rating-button{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border: 2px solid #f3f3f3;
    border-radius: 5px;
    gap: 20px;
    width: 100%;
    padding: 20px 30px;
  }
  
  .rating-button.selected{
    border-color: #ffc107;
  }
  
  .part1-radio-group .radio-label {
    transition: transform 0.3s ease, border-color 0.3s ease;
  }
  
  .part1-radio-group{
    display: flex;
    gap: 30px;
    margin-top: 30px;
  }
  
  .part1-radio-group .radio-label{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    width: 100%;
    text-align: center;
    align-items: center;
    border: 2px solid #f3f3f3;
    border-radius: 5px;
  }
  
  .radio-label.checked{
    border-color: #ffc107;
  }
  
  .part1-radio-group .radio-label:hover{
    cursor: pointer;
    transform: scale(1.1);
    border-color: #ffc107;
  }
  
  .rating-button:hover{
    cursor: pointer;
    transform: scale(1.1);
    border-color: #ffc107;
  }
  
  .rating-button img{
    max-width: 40px;
    width: 100%;
  }
  
  .radio-label input[type="radio"] {
    display: none; /* Hides the radio button */
  }
  
  .radio-image {
    cursor: pointer;
    border: 2px solid transparent;
    width: 100px; /* Adjust width as needed */
    height: 100px; /* Adjust height as needed */
    transition: border-color 0.3s ease;
  }
  
  .radio-label input[type="radio"]:checked + .part1text {
    border-color: #007BFF; /* Highlight border color when selected */
  }
  
  
  .wizard-container {
      font-family: Arial, sans-serif;
      max-width: 800px;
      margin: 0 auto auto;
      padding: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    .wizard-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    
    .section-title {
      font-size: 1.5em;
      margin-bottom: 10px;
    }
  
    .question-text2{
      color: #000!important;
      font-size: 20px!important;
  
    }
    
    .question-text {
      margin: 0px;
      font-family: Roboto, sans-serif;
      letter-spacing: .2px;
      font-style: normal;
      line-height: 34px;
      color: #6c757d;
      font-weight: 500;
      font-size: 27px;
  
    }
    
    .input-field {
      width: 100%;
      padding: 8px;
      margin-bottom: 10px;
    }
    
  .checkbox-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
    
    .radio-group label, .checkbox-group label {
      margin-bottom: 8px;
    }
    
    .button-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-direction: column;
    }
    
    .button {
      padding: 10px 20px;
      font-size: 1em;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
    
  
    .next-button {
      background-color: #ffc107;
      color: white;
      width: 102px;
    }
    
    .previous-button {
      background-color: #6c757d;
      color: white;
      width: 102px;
  
    }
    
    .skip-button {
      background-color: #ffc107;
      color: white;
    }
    
    .finish-button {
      background-color: #28a745;
      color: white;
    }
    
    .progress-bar-container {
      margin-top: 20px;
    }
    
    .progress-bar {
      width: 100%;
      height:5px;
      -webkit-appearance: none;
    }
    
    .progress-bar::-webkit-progress-bar {
      background-color: #f3f3f3;
    }
    
    .progress-bar::-webkit-progress-value {
      background-color: #ffc107;
    }
    
    .progress-text {
      text-align: center;
      margin-top: 5px;
    }
  
    .checkbox-group label{
  display: flex;
    }
  
    .wizard-section textarea{
      width: 100%;
      min-height: 250px;
    }
  
    .prev-next-container{
      display: flex;
      justify-content: space-between;
    }
  
   
    .personal-description{
  width: 100%;
  margin-bottom: 10px;
    }
    @media screen and (max-width:767px) {
      .wizard-section {
        justify-content: space-between;
        height: 100%;
        align-items: center;
    }
      .checkbox-group label input{
        width: auto;
      }
      .part1-radio-group .radio-label:hover {
        transform: scale(1);
      }
      
      .button-group {
        flex-direction: column;
    }
      .wizard-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
    }
      .part1-radio-group {
        flex-direction: column;
    }
    .part1-radio-group .radio-label {
      padding: 20px;
    }
  .rating-buttons{
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .rating-button {
    width: 40%;
  }
    }